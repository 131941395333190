import React from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import SEO from '../components/seo'
import Section from '../components/Common/Section'
import Container from '../components/Common/Container'
import Typography from '../components/Common/Typography'
import { Button } from '../components/Common/Button'
import RequestDemo from '../components/RequestDemo'
import ContactForm from '../components/contact-form'

import { useTranslations } from '../hooks/use-translations'

import * as s from '../pages/news.module.scss'

const LaserScanning = () => {
  const { t } = useTranslations()
  const images = useStaticQuery(imagesQuery)
  const [modal, setModal] = React.useState(false)

  return (
    <>
      <SEO
        title="Laser Scanning in Construction"
        description="Laser scanning provides precise 3D data capture and analysis, significantly enhancing accuracy in mapping, volume calculations, and digital twin creation for various applications, including surveying, facade documentation, infrastructure projects, thus ensuring a closer match between BIM models and physical execution."
      />
      <Section>
        <Container>
          <Typography variant="body1" color="gray" style={{ marginBottom: 8 }}>
            April 15, 2024
          </Typography>
          <Typography variant="h1" color="blue" size={40}>
            Laser scanning
          </Typography>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                Laser scanning during construction
              </Typography>
              <Typography variant="body1" size={18}>
                Laser scanning during the construction phase introduces a
                transformative approach to mapping and analyzing the evolving
                structure of buildings. It offers unmatched precision in
                recording the dimensions and characteristics of construction
                spaces. This technique supports the accurate dimensioning of
                under-construction areas, enabling the creation of detailed 3D
                BIM models essential for ongoing design adjustments or digital
                twin models. It markedly diminishes the likelihood of
                inaccuracies when compared to conventional methods, assuring an
                exact match between the construction's digital and physical
                forms.
              </Typography>
            </div>
            <div className="flex justify-center">
              <StaticImage
                width={564}
                src="../assets/images/solutions/laser-scanning/image-1.png"
                alt="Laser scanning during construction"
                title="Laser scanning during construction"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                Scope calculation with Laser Scanning
              </Typography>
              <Typography variant="body1" size={18}>
                Laser scanning is an effective tool for calculating volumes,
                especially in complex construction areas where precise
                measurements are crucial for material estimation, space
                utilization, and cost calculation. By capturing detailed 3D data
                of the site, laser scanning allows for accurate volume
                calculations of earthworks, materials, and spaces, streamlining
                the planning and resource allocation process. This accuracy
                ensures efficient material ordering and minimizes waste,
                contributing to more sustainable construction practices.
              </Typography>
            </div>
            <div className="flex justify-center">
              <StaticImage
                width={564}
                src="../assets/images/solutions/laser-scanning/image-2.png"
                alt="Scope calculation with Laser Scanning"
                title="Scope calculation with Laser Scanning"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className={s.margin_top_48}>
            <Typography variant="h2" size={24} mb={16}>
              Creating Digital Twins with Laser Scanning
            </Typography>
            <Typography variant="body1" size={18} mb={32}>
              Laser scanning is pivotal in creating digital twins of
              construction projects, offering a dynamic and detailed digital
              representation of physical sites. These digital twins enable
              stakeholders to simulate, analyze, and optimize construction
              processes and building operations in a virtual environment. The
              precise data captured by laser scanning forms the foundation of
              the digital twin, facilitating accurate modeling of structures and
              systems, which is essential for decision-making throughout the
              construction lifecycle and beyond.
            </Typography>
          </div>
          <div className={s.contentWrap}>
            <StaticImage
              width={564}
              src="../assets/images/solutions/laser-scanning/image-3.png"
              alt="Creating Digital Twins with Laser Scanning 1"
              title="Creating Digital Twins with Laser Scanning 1"
              placeholder="blurred"
            />
            <StaticImage
              width={564}
              src="../assets/images/solutions/laser-scanning/image-4.png"
              alt="Creating Digital Twins with Laser Scanning 2"
              title="Creating Digital Twins with Laser Scanning 2"
              placeholder="blurred"
            />
          </div>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                Aerial Laser Scanning for Geodetic Surveys
              </Typography>
              <Typography variant="body1" size={18}>
                Aerial laser scanning transforms geodetic surveys by quickly and
                accurately providing high-resolution topographic data over large
                and inaccessible areas. This method is crucial for capturing
                detailed terrain information, which is essential for planning,
                designing, and monitoring construction projects covered with
                vegetation and trees. The comprehensive data collected supports
                various applications, from initial site analysis to monitoring
                environmental impact, enhancing the efficiency and effectiveness
                of geodetic surveys.
              </Typography>
            </div>
            <div className="flex justify-center">
              <StaticImage
                width={564}
                src="../assets/images/solutions/laser-scanning/image-5.png"
                alt="Aerial Laser Scanning for Geodetic Surveys"
                title="Aerial Laser Scanning for Geodetic Surveys"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className={s.margin_top_48}>
            <Typography variant="h2" size={24} mb={16}>
              Facade Documentation with Laser Scanning
            </Typography>
            <Typography variant="body1" size={18} mb={32}>
              Laser scanning effectively captures detailed faсade documentation,
              providing architects and engineers with accurate surface data for
              analysis, restoration, and preservation projects. This technology
              allows for the detailed and non-intrusive recording of building
              exteriors, identifying structural issues, material degradation,
              and architectural features with high precision. The data serves as
              an invaluable source for maintaining architectural integrity and
              planning renovations or restorations.
            </Typography>
          </div>
          <div className={s.contentWrap}>
            <StaticImage
              width={564}
              src="../assets/images/solutions/laser-scanning/image-6.png"
              alt="Facade Documentation with Laser Scanning 1"
              title="Facade Documentation with Laser Scanning 1"
              placeholder="blurred"
            />
            <StaticImage
              width={564}
              src="../assets/images/solutions/laser-scanning/image-7.png"
              alt="Facade Documentation with Laser Scanning 2"
              title="Facade Documentation with Laser Scanning 2"
              placeholder="blurred"
            />
          </div>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                Implementing SLAM Technology in Laser Scanning
              </Typography>
              <Typography variant="body1" size={18}>
                Integrating SLAM (Simultaneous Localization and Mapping)
                technology with laser scanning represents a significant
                advancement, especially in environments where GPS signals are
                weak or unavailable. SLAM technology enables the creation of
                detailed and accurate maps of complex indoor spaces and
                unstructured environments without needing external reference
                points. This capability is advantageous in rapidly scanning
                construction sites, enhancing mobility, and reducing the time
                required to collect data
              </Typography>
            </div>
            <div className="flex justify-center">
              <StaticImage
                width={564}
                src="../assets/images/solutions/laser-scanning/image-8.png"
                alt="Implementing SLAM Technology in Laser Scanning"
                title="Implementing SLAM Technology in Laser Scanning"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className={s.margin_top_48}>
            <Typography variant="h2" size={24} mb={16}>
              Mobile Laser Scanning for Linear Infrastructure and Road Projects
            </Typography>
            <Typography variant="body1" size={18} mb={32}>
              Mobile laser scanning is particularly suited for linear
              infrastructure and road projects, offering the ability to capture
              comprehensive data quickly and accurately while on the move. This
              method is ideal for surveying large-scale projects, such as
              highways, railways, and utility lines, providing critical design,
              construction, and maintenance information. Mobile laser scanning
              facilitates the continuous collection of detailed topographic
              data, enabling precise planning, execution, and monitoring of
              linear construction projects.
            </Typography>
          </div>
          <Button
            size="large"
            className={cn('mx-auto', s.margin_top_48)}
            onClick={() => navigate('/digital-services/')}
          >
            Other digital services
          </Button>
        </Container>
      </Section>
      <RequestDemo
        title={t('Know more')}
        bgImage={images.demoBg.childImageSharp.gatsbyImageData}
        moduleData={{
          btnText: t('Request a call'),
          onClick: () => setModal(true),
          btnColor: 'white',
        }}
      />
      {modal && (
        <ContactForm
          onClose={() => setModal(false)}
          title={t('Request a call')}
          requestType="Request a call"
        />
      )}
    </>
  )
}

export default LaserScanning

const imagesQuery = graphql`
  query {
    demoBg: file(relativePath: { eq: "services-solutions-demo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`
