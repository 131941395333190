import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import Typography from '../Common/Typography'
import { Button, Color } from '../Common/Button'
import Container from '../Common/Container'
import { useTranslations } from '../../hooks/use-translations'

import * as s from './RequestDemo.module.scss'

interface RequestDemoProps {
  title: string
  id?: string
  bgImage?: IGatsbyImageData
  moduleData: {
    text?: string
    btnText: string
    onClick: () => void
    btnColor?: Color
  }
  appData?: {
    text: string
    btnText: string
    onClick: () => void
    hasBtnIcon?: boolean
    btnColor?: Color
  }
  buttonsElements?: React.ReactNode
  imageData?: {
    image: IGatsbyImageData
    title: string
  }
}

const RequestDemo: React.FC<RequestDemoProps> = ({
  title,
  imageData,
  bgImage,
  moduleData,
  appData,
  buttonsElements,
  id,
}) => {
  const { t } = useTranslations()
  const images = useStaticQuery(imagesQuery)

  return (
    <section className={s.section} id={id}>
      <div className={s.image_bg}>
        <GatsbyImage
          alt={t('Building')}
          title={t('Building')}
          className="w-100 h-100"
          image={bgImage || images.bg.childImageSharp.gatsbyImageData}
        />
      </div>
      <div className={s.overlay} />
      <Container className={s.container}>
        <div className={s.column}>
          <Typography variant="h2" size={40} color="white">
            {title}
          </Typography>
          {(moduleData.text || appData) && (
            <div className={s.description}>
              {moduleData.text && (
                <Typography variant="body1" color="white" className={s.text}>
                  {moduleData.text}
                </Typography>
              )}
              {appData && (
                <Typography variant="body1" color="white" className={s.text}>
                  {appData.text}
                </Typography>
              )}
            </div>
          )}
          <div className={s.btns}>
            {appData && (
              <Button
                size="large"
                color={appData.btnColor}
                onClick={appData.onClick}
                hasIcon={appData.hasBtnIcon}
                style={{ marginBottom: 10 }}
              >
                {appData.btnText}
              </Button>
            )}
            <Button
              size="large"
              color={moduleData.btnColor}
              onClick={moduleData.onClick}
              style={buttonsElements ? { marginBottom: 10 } : undefined}
            >
              {moduleData.btnText}
            </Button>
            {buttonsElements}
          </div>
        </div>
        {imageData && (
          <GatsbyImage
            image={imageData.image}
            title={imageData.title}
            alt={imageData.title}
            className={s.image}
          />
        )}
      </Container>
    </section>
  )
}

export default RequestDemo

const imagesQuery = graphql`
  query {
    bg: file(relativePath: { eq: "demo-bg.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`
